import { roles } from 'config/userRole';

const routes = [
  {
    path: '/users/menu-orders',
    component: 'pages/authenticated/user/menuOrder',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/admin/gm/templates',
    component: 'pages/authenticated/admin/gmTemplate/',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/templates/create',
    component: 'pages/authenticated/admin/gmTemplate/AddEditTemplate',
    navbar: 'gm',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/templates/edit/:id',
    component: 'pages/authenticated/admin/gmTemplate/AddEditTemplate',
    navbar: 'gm',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/users/chat',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/gm/chat/:id',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/web/chat/:id',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/admin/chat',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/chat/:id',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/web/chat/:id',
    component: 'pages/authenticated/Chat',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/my-page',
    component: 'pages/authenticated/Landing',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/order',
    component: 'pages/authenticated/admin/orderManagement',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/order/pro',
    component: 'pages/authenticated/admin/orderManagement/proUser',
    navbar: 'web',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/order/accept-order/:token',
    component: 'pages/authenticated/admin/orderManagement/AcceptOrder',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/order/pro/accept-order/:token',
    component: 'pages/authenticated/admin/orderManagement/proUser/AcceptOrder',
    navbar: 'web',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/users/menu-orders',
    component: 'pages/authenticated/MyPage',
    navbar: 'gm',
    auth: true,
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/gm/my-page',
    component: 'pages/authenticated/MyPage',
    auth: true,
    navbar: 'gm',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/gm/templates/details/:id',
    component: 'pages/authenticated/user/gmTemplate/GmTemplateDetails',
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/users/gm/templates/details/:id',
    component: 'pages/authenticated/user/gmTemplate/GmTemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/admin/gm/notifications',
    navbar: 'gm',
    component: 'pages/authenticated/admin/notification',
    auth: true,
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/notifications/create',
    navbar: 'gm',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/gm/notifications/edit/:id',
    navbar: 'gm',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    allowedRoles: [roles.admin],
  },
  {
    path: '/users/manuscripts',
    component: 'pages/authenticated/user/ManuscriptManagement',
    auth: true,
    navbar: 'gm',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/design/details/:id',
    component: 'pages/authenticated/user/TemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/gm/design/details/:id',
    component: 'pages/authenticated/user/TemplateDetailsGm',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/menu-orders/create',
    component: 'pages/authenticated/user/menuOrder/addEditMenuOrder/CreateMenuOrder',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
];

export default routes;
