const ja = {
  translation: {
    // define translations below
    form: {
      required: 'この項目は必須です。',
      email_format: 'メールの形式で入力して下さい。',
      email: 'メールの形式で入力して下さい。',
      password: {
        minLength: 'パスワードは8～20桁で必要があります。',
        confirm: 'パスワードの確認が一致しません。',
        strong:
          'パスワードには、大文字1文字、特殊文字1文字、および少なくとも8文字が含まれている必要があります。',
      },
      positive_num: '正の数で入力してください。',
      image: {
        invalid_type: '有効なファイル形式ではありません。',
        invalid_type_with_size:
          'Invalid image input. Image dimensions must be 1236x717 and file size should not be greater than 5MB.',
        unsupported_file_format: `サポートされたファイル形式にて再度アップロードしてください。
        サポートファイル形式：JPEG, PNG, JPG`,
      },
      name: {
        max: '255文字以内で入力してください。',
      },
      date: {
        invalid_prev_date: '過去の日付は選択できません。',
      },
      katakanaOnly: 'フリガナを入力してください。',
      email_max: '50文字以下で入力してください。',
      details_max: '500文字以下で入力してください。',
      template: {
        duplicate: '既に同じ名前のテンプレートが登録されています。',
      },
      chat: {
        required: 'メッセージを入力してから送信ボタンを押してください。',
        max: '250文字以内で入力してください。',
        redirect: 'メニュー注文管理ページへ自動で転送されます。',
      },
      attachment: {
        max: '最大アップロード数を超えました。アップロード可能な最大数は５個までとなります。',
        invalid:
          'ファイルの種類は、.doc、.docx、.txt、.xlsx、.png、.jpg、.jpeg、.pdf のいずれかである必要があり、ファイルはサイズは2MB までとなります。再度有効なファイルのアップロードをお試しください。',
      },
      final_offer: {
        max: '最大アップロード数を超えました。アップロード可能な最大数は５個までとなります。',
        invalid:
          'ファイルの種類は、.png、.jpg、.jpeg、.pdf のいずれかである必要があり、ファイルはサイズは2MB までとなります。再度有効なファイルのアップロードをお試しください。',
      },
      file: {
        size: 'ファイルサイズが大き過ぎます。',
        format: 'サポートされていないファイル形式です。',
      },
      number: {
        invalid: '有効な数値を入力してください。',
        min: '数字は{{value}}以上でなければなりません。',
        max: '一度に印刷できる枚数は{{value}}枚以内となります。',
      },
      string: {
        max: 'このフィールドは{{max}}文字を超えてはいけません。',
      },
      long_message: {
        max_textfield: 'メッセージが長すぎます。',
      },
    },
    labels: {
      first_name: 'ファーストネーム',
      last_name: '苗字',
      login: 'ログイン',
      signup: '新規登録',
      signin: 'サインイン',
      remember_me: '私を覚えてますか',
      forgot_password: 'パスワードをお忘れですか？',
      email_address: '電子メールアドレス',
      email: 'メールアドレス',
      password: 'パスワード',
      confirm_password: 'パスワードを認証する',
      submit: '送信',
      update: 'アップデート',
      save: '保存',
      add_new: '新しく追加する',
      reset_password: 'パスワードを再設定する',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワードを確認',
      enter_keyword: 'キーワードを入力してください',
      get_started: 'はじめましょう',
      integrations: '統合',
      settings: '設定',
      documentation: 'ドキュメンテーション',
      fullname: 'フルネーム',
      inquiry_content: 'お問合わせ内容',
      navigation: 'ナビゲーション',
      resources: 'リソース',
      cancel: 'キャンセル',
      action: 'アクション',
      welcome: 'ようこそ、',
      menu: 'メニュー',
      templates: 'テンプレート',
      contact: 'コンタクト',
      terms_privacy: '利用規約・個人情報保護方針',
      register_free: '無料登録',
      logout_message: 'ログアウトしますか？',
      create: '新規作成',
      edit: '編集',
      delete: '削除',
      deleted: '削除済み',
      start_editing: '編集開始',
      tool_tip: 'お気に入りに追加するにはログインが必要です。',
      group_template: 'テンプレートグループ (MEX for WEB)',
      select_template: 'テンプレートを選択',
      cancel_modal: 'キャンセル',
      confirm_modal: 'はい',
      select: '選択して下さい',
      upload_image: '画像をアップロード',
      no_file_chosen: 'ファイルが選択されていません',
      no_data: 'データがありません。',
      all: 'すべて',
      menu_details: 'メニューデザイン詳細',
      close: '閉じる',
      public: '公開',
      private: '非公開',
      insert_text: 'テキストを挿入',
      register: '登録',
      renew: '更新',
      view: '閲覧',
      done: '済',
      about: '会社概要',
      terms_of_use: '利用規約',
      try_web_version: `WEB版
      を使ってみる`,
      try_mobile_version: `App Storeから
      アプリをダウンロード`,
      about_web_version: 'WEB版について',
      about_mobile_version: 'ダウンロードについて',
      back: '戻る',
      hours: '時間',
      minutes: '分',
      hour: '時間',
      minute: '分',
      ago: '前',
      notifications: '通知',
      print: '印刷注文',
      pdf: 'PDFダウンロード',
      service: 'サービス',
      mex_for_gm: `MEX for GM`,
      templates_for_gm: 'MEX for GM 用テンプレート',
      templates_for_web: 'MEX for WEB 用テンプレート',
      mex_for_web: 'MEX for WEB',
      confirm: '続行',
      download_csv: 'CSVとしてダウンロード',
      menu_order_management: 'メニュー注文管理',
      request_no: 'リクエストNo.',
      designer: 'デザイナー',
      customer: 'カスタマー',
      thumbnail: 'サムネイル',
      date_requested: 'リクエスト日時',
      proceed: '適用',
      copy: 'コピー',
      initializing: '初期化中',
      loading: '読み込み中',
      menu_order: 'メニュー作成依頼',
      optional: '任意',
      select_option: '選択',
      card_number: 'カード番号',
      expiration_date: '有効期限',
      security_code: 'セキュリティコード',
      card_name: 'カード名義人',
      refund: '返金',
      download_all: '全てのファイルをダウンロード',
      template_type: 'テンプレートタイプ',
      standard: 'スタンダードユーザー用',
      pro: 'プロユーザー用',
      pro_2: 'プロユーザー２用',
      try_menu_express: 'Menu Expressを使ってみる',
      admin: '管理者',
      menu_request_un_auth: `現在管理者としてログイン中です。
      メニュー作成依頼はユーザーアカウントのみ利用可能です。`,
      menu_request_pro_auth: `プロユーザーアカウントでログインされました。
      プロユーザー用テンプレートから再度サービスをご利用ください。`,
      menu_express_for_web: `Menu Express for 差込メニュー（オンライン作成と作成リクエストが可能です）`,
      menu_express_for_gm: `Menu Express for グランドメニュー（雛形を選んで作成リクエストが可能です）`,
      base_business: `業態や雰囲気から
      メニューを絞り込んで
      作成リクエスト`,
      show_previous_revision: 'デザイン履歴確認',
      design_approval: 'デザインを承認する',
      design_revision_request: 'デザインの修正を依頼する',
      accepted: 'デザイン承認',
      order_menu: '挿入メニューを撮影',
      attach_data: 'データを添付',
      upload_design: 'デザインをアップロード',
      supplemental_text: 'テキストで補足',
      instructions: '作成内容指示記入（価格や希望など　※必須）',
      surface_finish: '表面加工',
      with_pouch: 'パウチあり',
      without_pouch: 'パウチなし',
      delivery_type: '配送先種別',
      store: '店舗',
      branch_office: '支店',
      go_to_shipping_input: 'お届け先入力へ',
      print_request: 'メニュー作成依頼',
      shipping_name: '配送先名称',
      business_phone_number: '業務店電話番号',
      delivery_postal_code: '配送先郵便番号',
      delivery_prefecture1: '配送先住所',
      delivery_prefecture2: '都道府県',
      shipping_address: '住所',
      delivery_request_date: '配送希望日',
      print_request_count: '印刷依頼枚数',
      order_completed: '注文が完了しました。',
      order_completed_message_1: `デザイン作成後、デザイナーより完了通知が送信されます。`,
      order_completed_message_2: `お知らせに記載されているURLよりデザインをご確認いただき、「承認」または「修正」のご返信をお願いいたします。`,
      order_completed_message_3: `1週間以内に完了通知への返信がない場合は、自動的に承認となります。`,
      order_number: 'オーダーNo',
      approve_design: 'デザインを承認',
      revision_request: '修正依頼',
      show_previous_revisions: 'デザイン履歴確認',
      no_revisions: '今のところ修正はしていない。',
      send_revision: '改訂版をアップロードする',
      delivery_details: '依頼内容詳細',
      revision_number: '改訂番号',
      accept_revision_message: '新しく更新されたデザインを承認しますか？',
      order_success: 'ご注文は正常に受け付けられました。',
      select_revision_download: 'ダウンロードしたいデザインを選択してください',
      download: 'ダウンロード',
      go_back_to_gohanju_navi_app: 'ご繁盛ナビアプリへ戻る',
      pro_en: 'PRO',
      narrow_by_group: 'グループ（ブランド・用途など）で絞り込み',
      contact_the_business_store: '業務店様/酒販店様に連絡をする',
      No_file_available: '使用可能なファイルが添付されていません。',
    },
    pages: {
      login: {
        signup: {
          description: 'アカウントをお持ちですか？',
        },
        invalid_auth: 'メールアドレスかパスワードが正しくありません。',
      },
      signup: {
        agree_to_terms: '[サインアップ]をクリックすると、読んだことに同意したことになります',
        signup_complete:
          '確認メールが受信トレイに送信されました。 リンクをクリックして、登録プロセスを完了します。',
        terms_conditions: '規約と条件',
        create_free_account: '無料アカウントを作成する',
      },
      forgot_password: {
        sub_heading: 'アカウントを復旧するには、以下にメールアドレスを入力してください。',
        success: 'パスワードをリセットする方法については、受信トレイを確認してください。',
      },
      reset_password: {
        sub_heading: '新しいパスワードを入力してください。',
        success: 'パスワードは正常に更新されました。',
      },
      users: {
        user_created: 'ユーザーが作成されました。',
        user_updated: 'ユーザーの詳細が更新されました。',
        user_deleted: 'ユーザーが削除されました',
        add_user: 'ユーザーを追加する',
        edit_user: 'ユーザー編集',
        delete_user: 'ユーザーを削除',
        first_name: 'ファーストネーム',
        last_name: '苗字',
        email_address: '電子メールアドレス',
        status: '状態',
        delete_confirmation: '選択したユーザーを削除してもよろしいですか？',
      },
      templates: {
        template_list: 'テンプレート一覧',
        name: '名称',
        design: 'デザイン',
        menu: 'メニュー',
        group: 'グループ',
        disclosure: '公開',
        create: '新規作成',
        search: 'キーワードで検索 (ID、名称、業態、タイプ)',
        designs: 'デザイン',
        all: '全て',
        narrow_down: '絞り込む',
        refine_narrow_down: '絞り込む',
        sort: '並び順',
        new: '新',
        old: '旧',
        edit_register_template: 'テンプレートを編集・登録',
        template_name: 'テンプレート名',
        group_tab: 'グループ タブ',
        display_order: '表示並び',
        template_image: 'テンプレート画像',
        thumbnail_image: 'サムネイル画像',
        edit_template: 'テンプレートを編集',
        register_template: 'テンプレートを登録',
        jp: '日本語',
        en: '英語',
        name_already_used: '既に使われている名称は利用できません',
        multiple_select_possible: '複数選択可能',
        display_sort_order:
          'テンプレート一覧で、この数字順に並びます（指定なしの場合は、最後になります）',
        delete_template_confirmation: 'このテンプレートを削除してもよろしいでしょうか。',
        no_group_tabs: '選択されたグループで利用可能なタブがありません。',
        delete_confirmation: 'このテンプレートを削除してもよろしいでしょうか。',
        template_deleted_message: 'テンプレートが削除されました。',
        template_updated_message: 'テンプレートの詳細が更新されました。',
        menu_design_deleted_message: 'メニューデザインが削除されました。',
        open_editor: 'エディターを開く',
        business_categories: '業態',
        business_type: 'Business Type',
        type: 'タイプ',
        saving_details: 'テンプレートの詳細を保存しています。',
        template_in_use: 'このテンプレートは使用中のため削除できません。',
      },
      groups: {
        renew: '更新',
        group_list: 'グループ一覧',
        group_id: 'グループID',
        group_name: 'グループ名',
        login_id: 'ログインID',
        login_password: 'パスワード',
        membership_limit: '会員数上限',
        no_registered_members: '登録会員数',
        template: 'テンプレート',
        max_number_of_member: '会員上限数',
        number_of_registered_member: '登録会員数',
        tab: 'タブ',
        delete_confirmation: '本当にこのグループを削除しますか？',
        group_tab: 'グループ タブ',
        create_new: '新規作成',
        group_edit: 'グループ編集',
        eye_catching_image: 'アイキャッチ画像 1236 x 717',
        eye_catching_url: 'アイキャッチ用URL',
        group_deleted_message: 'グループが正常に削除されました。',
      },
      notifications: {
        title: 'タイトル',
        message: 'メッセージ',
        notification_list: '通知一覧',
        notification_deleted_message: '通知が削除されました。',
        notification_category: '通知カテゴリー',
        schedule_transmission: '予約予定日時',
        delete_confirmation: '本当にこの通知を削除しますか？',
        create_notification: '通知を作成',
        edit_notifification: '通知を編集',
        registration: '登録',
        schedule_reservation_date: '予約予定日',
        schedule_message:
          'この日時を過ぎると、このメッセージが予約され、予約された端末へ順次送信します。予約後は変更できません。',
        category: {
          normal: '通常',
          important: '重要',
          news: 'ニュース',
        },
        email: {
          title: '電子メールでも通知を送信する',
        },
        search: 'キーワードで検索 ',
      },
      activate: {
        heading: 'アカウントをアクティブ化',
        subtitle: 'アカウントをアクティブ化するためのパスワードを設定します。',
        activated: 'アカウントが有効になりました。 これで、アカウントにログインできます。',
      },
      dashboard: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        new_users: '新しいユーザー',
        total_sales: '総売上高',
        total_orders: '総注文数',
      },
      not_found: {
        title: 'ページが見つかりません',
        sub_heading: 'お探しのページは削除されたか、別の場所へ移動した可能性があります。',
        back_to_top: 'トップページへ戻る',
      },
      faq: {
        heading: 'よくあるご質問',
        sub_heading: 'お客様からお問い合わせいただく質問をQ&A形式でまとめました。',
      },
      inquiry: {
        heading: 'お問い合わせ',
        sub_heading: 'このままお問い合わせされる方は下記のフォームにご入力ください。',
        success_message: 'お問い合わせを送信しました。',
        failed_message: '送信中にエラーが発生しました。',
      },
      profile: {
        heading: 'プロファイル編集',
        sub_heading: 'アカウント情報を更新します。',
        success_message: '詳細が正常に更新されました。',
        failed_message: '更新に失敗しました。',
      },
      landing: {
        main_heading: '飲食店のメニューが',
        sub_heading: '無料で作れるお手軽サービス',
        why_heading: 'なぜベーステンプレートを使用するのですか？',
        info_message: `WEBでも
        Androidでも使える
        WEB版
        できました！`,
        operation: {
          header: '簡単な操作',
          title: `操作は簡単！
          たった3分でメニューを作成`,
          description:
            'PCやスマホで写真を撮影し、プロがデザインした600種類以上のテンプレートを選択し、文字を入力するだけの簡単な操作で、プロがデザインしたような飲食店メニューが作成できます。 ※居酒屋・カフェ・バー・バル・中華料理など様々な業態に対応しています。',
          btnlbl: '→ 初めてのお客様へ',
        },
        troubleshooting: {
          header: 'トラブルシューティング',
          title: `メニュー制作・集客の
          お悩みを解決！`,
          title_two: `デザイナーに直接相談
          しながらオリジナルメ
          ニューを作成できる！`,
          description:
            '印刷連携機能を合わせ持ったアプリなので店頭・店内での演出が可能です。 さらに、各SNSシェアの機能も搭載しているのでSNSを活用した拡散で集客にも効果があります。',
          description_two: `テンプレートを使ってお客様にてデザインを編集いただける機能と併せて、デザイナーへメニューデザイン作成を依頼することも可能です。
          依頼後はデザイナーとページ内チャット機能でリアルタイムにコミュニケーションができ、
          理想のデザインへと近づけることができます。`,
          btnlbl: '→ Menu Expressとは',
        },
        template_list: '→ テンプレート一覧',
        quality_title: {
          first_title: 'プロが手がけたハイクオリティな',
          second_title: '600種類のデザイン',
        },
        quality_description: `毎月新作が追加されるテンプレートで皆様のほしいテンプレートがきっと見つかります。`,
        contribution: {
          first_title: '効果的な活用シーン',
          second_title: 'Menu Expressはお店のご繁盛に貢献します！',
          third_title: '効果的な活用方法のご紹介。',
          description: `Menu Expressはお店にとってはプラスなことばかり。
          効果的な活用方法をご紹介します。`,
        },
        btn_details: '詳しく見る →',
      },
      about: {
        web_heading: 'Menu Express for WEBとは',
        web_heading_pro2: 'Menu Express for WEB PRO版とは​',
        gm_heading: 'Menu Express for GM(グランドメニュー)とは',
        web_content: 'オンラインで簡単にお客様ご自身で差し込みメニューを作成できるサービスです',
        gm_content: `オンラインでお客様ご自身でイメージなどを選択し、
        簡単に『デザイナーへメニュー作成を依頼できる』サービスです。`,
        main_heading: '初めてのお客様へ',
        page_title: 'Menu Expressとは',
        page_definition: `飲食店の課題を解決するお役立ちサービスです!
        貴方のお店に「課題やお悩み事はありますか?」`,
        banner_gm_first_title: `お客様のお店におけるグランドメニューを
        「簡単に」「安価に」作成するサービスです。`,
        banner_first_title: 'PCやスマホでオリジナルメニューを',
        banner_second_title: 'スグに簡単作成',
        gm_banner_descriptions_one: `飲食店の課題を解決するお役立ちサービスです!
        貴方のお店に「課題やお悩み事はありますか?」
        メニューはスタッフの一員です。
        お店が抱える日々のお悩み事や課題を
        メニューを活用して素早く解決。
        『自分で作るのは自信が無い。。。』
        といったお悩みも解決！`,
        gm_banner_descriptions_two: `Menu Express for GM  では
        数ある業種の中からご自身のお店に合ったカテゴリーを選択して
        表示されたテンプレートを選ぶだけ。
        その後はデザイナーとチャット形式で直接やり取りし、
        お客様の理想のイメージに近づけます！`,
        banner_descriptions: `メニューを使用する場所や目的が決まったら
          あとは簡単4つのSTEP
          出力サービスやSNS投稿、画像補正など機能も充実。
          豊富なメニューテンプレートからお気に入りを見つけてください。
          こだわって作る料理だからこそ
          メニューにもこだわりを・・・。`,
        banner_descriptions_pro2: `メニューを使用する場所や目的が決まったら
          あとは簡単4つのSTEP
          豊富なメニューテンプレートからお気に入りを見つけてください。
          その後はデザイナーにお任せ！
          こだわって作る料理だからこそ
          メニューにもこだわりを・・・。`,
        footer_description: `まずはWEB版を使って
        あなたの店舗を魅力的に`,
        footer_btn_first_label: 'WEB版 ',
        footer_btn_second_label: 'を使ってみる',
        steps: {
          take_photo: '写真を撮影 ',
          process_photo: `撮った写真を
          加工`,
          enter_characters: `文字や金額を
          入力`,
          complete: `完成！
          印刷やSNS投稿で
          情報発信！`,
        },
        gm_steps: {
          step_one: `カテゴリーからお客様
          のイメージに近い項目
          を選択`,
          step_two: `フォントやページ数を
          選択`,
          step_three: `デザイナーとチャット
          でイメージを話し合い`,
          step_four: `完成！
          ご自身で印刷または
          そのまま印刷依頼も可
          能！
          数日後には実メニュー
          をゲット！`,
        },
        pro2_steps: {
          step_one: `お好みの
                  テンプレ
                  ートを選
                  択`,
          step_two: `テンプレ
                  ートへ追
                  加したい
                  写真や画
                  像を添付`,
          step_three: `文字や金
                  額,制作枚
                  数を入力
                  しデザイ
                  ナーへ依
                  頼`,
          step_four: `完成！
                  ご登録の
                  配送先へ
                  納品！`,
        },
        effects: {
          title: '導入効果の一例',
          increase_price: {
            title: '客単価アップ',
            description:
              '締めの一品や食後のデザートなど「あと1品」のアプローチでPOP作成前よりも客単価がアップしました。',
          },
          cost_down: {
            title: '原価率ダウン',
            description:
              '過剰在庫の有効活用で食材ロスを防ぎ、利益率の高いメニューをPOPでアプローチする事で以前と同じ売上でも原価率が下がり利益アップになりました。',
          },
          increase_repeat: {
            title: 'リピート率アップ',
            description:
              '全てのテーブルに希少食材を使った事前予約の宴会メニューをPOPで訴求した事で、今までは無かった"来店時の次回予約"が増えました。',
          },
          increase_sales: {
            title: '出数アップとブランド化',
            description:
              'ぜひ食べてもらいたいお勧めメニューを店頭・店内で接点回数を増やし訴求する事でほとんどの方に注文される"お店の看板メニュー"になりました。',
          },
        },
        how_to_use: {
          title: '活用術の一例',
          food_loss: {
            title: '食材のロスカット',
            description:
              '出数のバラつき等により、食材が過剰在庫の際に通常メニューに無い新メニューや盛り合わせのPOPを作成して訴求することで食材ロスを未然に防ぎます。',
          },
          opportunity_loss: {
            title: '閑散期にも安定した集客で 機会損失を阻止',
            description:
              '雨の日など通常は集客が落ちる時でも「雨の日キャンペーン」等のテンプレートで作成したPOPをリアルタイムでSNS配信すれば安定集客に繋がります。',
          },
          improving_operations: {
            title: '"ラストワンマイル"の オペレーション向上',
            description:
              'POP作成時に使用する料理をロスと捉えず、ホールスタッフが食べて料理の味や特徴の理解を深めることで、お客様へしっかりと料理の案内ができオペレーションの向上に繋がります。',
          },
        },
      },
      manuscripts: {
        title: '原稿管理',
        headers: {
          id: 'ID',
          name: '原稿名',
          file: 'ファイル',
          attachments: 'アップロード済み画像ファイル',
          created_at: '作成日',
          action: 'アクション',
        },
        create_manuscript: '原稿を作成する',
        manuscript_name: '原稿名',
        ocr: 'OCR（画像データから文字をスキャンします）',
        upload_file: 'ファイルをアップロードする',
        open_camera: 'カメラ起動',
        description: '説明',
        remarks: '備考',
        confirm_delete: '原稿を削除してもよろしいですか？',
        required: '必須',
        manuscript_in_use: 'この原稿は現在使用中のため削除できません。',
        name_in_use: '入力された原稿名はすでに使用されています。別の名前を設定してください。',
        attachment: 'イメージ添付',
        invalid_attachment:
          'ファイルの種類は、.png、.jpg、.jpeg、.gif のいずれかである必要があり、ファイルはサイズは4MB までとなります。再度有効なファイルのアップロードをお試しください。',
      },
      chat: {
        title: 'コミュニケーション',
        files: {
          offers: '添付された最終版をご確認ください。',
          manuscripts: '添付資料をご参照ください。',
        },
        additional_fee: {
          request: '追加料金の請求履歴',
          create: '追加料金の請求',
          paid: '支払い済み',
          un_paid: '未払い',
          remarks: '備考',
          amount: '金額',
          create_button: '請求',
          success_create: '追加料金の設定に成功。',
          amount_validation: '100円未満は不可',
          confirm_payment: '支払い確認',
          additional_amount: '追加金額',
          proceed_payment: '支払い手続き',
          payment_notice: '一度お支払いいただいた代金は返金いたしません。',
          pay: '支払い',
          request_id: 'リクエストID',
          status: '状態',
          payment_note: '※お支払いは予約確認時にご登録いただいたクレジットカードとなります。',
          refund_note: '返金処理を実行してもよろしいですか？',
          cancel_note: 'キャンセルリクエストを送信してもよろしいですか？',
          cancelled: 'キャンセル済み',
          refunded: '返金済み',
          cancelled_success: '追加料金のキャンセルに成功',
          refund_success: '追加料金の払い戻しに成功',
          already_refunded: '料金はすでに返金されている',
          payable_amount: '支払額は50万円を超えないこと。',
        },
        offer: {
          checkbox: '修正版を依頼者様の電子メールアドレスに送信する',
          request_fee: '追加料金の請求履歴',
          pay_additional_fee: '追加料金の支払い',
          button: '最終版を送信',
          complete: {
            label: 'この会話には返信できなくなります。',
            button: '詳細画面で最終改訂版を確認する',
          },
          upload: {
            title: '最終版を送信する',
            label: '複数選択可。',
            button: 'アップロード',
          },
          sent: {
            success: '最終オファーを送信しました。',
            failed: '最終オファーを送信できませんでした。',
          },
        },
      },
      design: {
        deleted: 'このメニューデザインは削除されました。',
      },
    },
    menu: {
      home: 'ホーム',
      about: 'サービスについて',
      template_list: 'テンプレート一覧',
      user_guide: 'ユーザーガイド',
      contact_us: 'お問い合わせ',
      inquiry: 'お問い合わせ',
      faq: 'よくあるご質問',
      dashboard: 'ダッシュボード',
      users: 'ユーザー',
      orders: '注文',
      reports: 'レポート',
      integrations: '統合',
      profile: 'プロフィール',
      my_page: 'マイページ',
      history: '履歴',
      login: 'ログイン',
      logout: 'ログアウト',
      terms: '利用規約',
      privacy_policy: 'プライバシーポリシー',
      documentation: 'ドキュメンテーション',
      api_reference: 'APIリファレンス',
      support: 'サポート',
      styleguide: 'スタイルガイド',
      templates: 'テンプレート管理',
      designs: 'メニューデザイン管理',
      groups: 'グループ管理',
      last_update: '最終更新',
      days: '日前',
      months: 'ヶ月前',
      years: '年前',
      today: '今日',
      templates_back: 'テンプレート一覧に戻る',
      account_information: 'アカウント情報を閲覧・編集する',
      change_password: 'パスワードを変更する',
      cancel_membership: '退会のお手続きへ',
      guide: '効果的な活用シーン一覧',
      counter: '効果的な店頭演出',
      store: '効果的な店内演出',
      sns: 'SNSを有効活用',
      notifications: '通知管理',
      order_management: `注文管理`,
      chat: 'チャット',
      manuscript_management: '原稿管理',
      menu_creation_order_management: 'メニュー作成・注文管理',
      menu_creation_request_history: 'メニュー作成リクエスト履歴',
      missing_template:
        '選択されたテンプレートは既に削除されております。別のテンプレートを再度選択してください。',
      missing_manuscript:
        '選択された原稿は既に削除されております。別のテンプレートを再度選択してください。',
      missing_both:
        '選択されたテンプレートと原稿は既に削除されております。別のテンプレートと原稿を再度選択してください。',
      select_template: 'テンプレートを選択',
      select_manuscript: '原稿を選択',
      select_both: '原稿とテンプレートの再選択',
      custom_page_error: 'ご注文可能な最大ページ数は{{value}}ページ以下となります。',
      price_error: 'ご注文可能な最大金額は{{value}}円以下となります。',
    },
    table: {
      no_data: '該当するデータがありません。',
    },
    select: {
      options: {
        general: '一般',
        all: 'すべて',
      },
    },
    template: {
      filters: 'フィルター',
      design_found: 'デザインがみつかりました', // 1 design found
      designs_found: '件のデザインがヒットしました', // 2 or more designs found
      design_empty: '該当するテンプレートがありません。',
      search_template: 'テンプレートを検索',
      business_categories: {
        narrow_down: '業態を絞り込む',
        all: 'すべて',
        izakaya: '居酒屋',
        japanese_food: '和食・寿司・海鮮料理',
        udon_soba: 'うどん・そば',
        eel: 'うなぎ',
        yakitori: '焼き鳥',
        yakinikum_offal: '焼肉・ホルモン',
        tonkatsu: 'とんかつ・しゃぶしゃぶ',
        kushiage: '串揚げ・天ぷら',
        okonomiyaki: 'お好み焼き・もんじゃ焼き',
        okinawan: '沖縄料理',
        western_food: '洋食・フレンチ・イタリアン',
        steak_hamburger: 'ステーキ・ハンバーグ',
        chinese_food: '中華料理・韓国料理',
        curry_thai_food: 'カレー・タイ料理',
        cafe: 'カフェ・バー',
        others: 'その他',
      },
      refine: {
        narrow_type: 'タイプを絞り込む',
        all: 'すべて',
        vertical_type: 'タテ型',
        horizontal_type: 'ヨコ型',
        daily_recommended: '日替り・おすすめ',
        limited_menu: '限定メニュー',
        seasonal_menu: '季節メニュー',
        morning: 'モーニング',
        lunch: 'ランチ',
        dinner: 'ディナー',
        luxury: '高級',
        the_masses: '大衆',
        all_you: '飲み放題・食べ放題',
        year_end: '忘新年会・歓送迎会',
        banquet_course: '宴会・コース料理',
        drink: 'ドリンク',
        take_out: 'テイクアウト',
        others: 'その他',
      },
      menu_management: {
        popularity: '人気',
        last_10_days: '過去10日間',
        newest_first: '新しい順',
        oldest_first: '古い順',
        favorites: 'お気に入り',
        editing_and_edited: '編集中・編集済み',
        last_update_30: '最終更新 30分前',
        last_update_8: '最終更新 8時間前',
        exceed_refund: '払い戻し金額は購入金額を超えることはできません。',
        refund_amount: '返金金額',
      },
    },
    guide: {
      heading: '効果的な店舗演出を目指した情報や',
      sub_heading: 'サービス ・ 機能も充実！',
      counter_heading: '効果的な店頭演出',
      counter_content: `店頭の見せ方や料理の訴求は、
        お客様がお店を選ぶうえでの
        重要なポイントの１つです。`,
      store_heading: '効果的な店内演出',
      store_content: `店内では、卓上や壁面を使って
      課題やお悩みを解決します。`,
      sns_content: `メニューエクスプレスで作成したメニューは、
        SNSでリアルタイムに配信が出来るので、
        集客ツールとしての利用や、
        宴会等の予約獲得に効果的です。`,
      templates_heading: `デザイナーに依頼することで、`,
      templates_content: `店舗の雰囲気にマッチした
      オリジナリティー溢れるメニュー
      デザインが実現！`,
    },
    counter: {
      point_1: `料理のイメージがわかる
      メニューになっていますか？`,
      point_content_1:
        '文字だけのメニューだとイメージが伝わりきれません。写真入りのメニューを使用することで、より一層料理のイメージが伝わります。特に店頭メニューはスタッフの方へ聞くことも出来ないので料理写真は重要です。',
      point_2: `お店のこと、
      もっと知ってもらいたい。`,
      point_content_2:
        '料理のテンプレート以外にも休業日や営業時間、禁煙や喫煙のご案内などお店の情報を店頭で発信すれば、お店の情報が伝わりそこから来店の動機にも繋がります。',
    },
    store: {
      point_1: `そこの壁、
      使わない理由ありますか？`,
      point_content_1: `何気なくではありますが、一番見られている場所はご存知ですか？それはテーブル周りの壁面です。 宴会POPや女子会のポップで次回予約を獲得したり、締めの一品を訴求して客単価アップに繋げたりと、お店の課題に合わせて掲示してみてはいかがでしょうか。`,
      point_2: `お客様を
      飽きさせない事も大事です。`,
      point_content_2: `旬な食材を使った料理で毎日メニューが変わる。そんなお勧めメニューは魅力ですよね。 ただ、毎日変えるとなると手間も掛かってしまいますが、差し替えが簡単なメニューボードを使えば毎日変えても現場のストレスはありません。グランドメニューとは別でキラーコンテンツの訴求に効果的です。`,
      point_3: 'ご縁を結びます',
      point_content_3: `スタッフ募集のテンプレートを使えばお店のファンの方からや、その周りの方から応募いただけたりすれば、求人費のコスト削減にも繋がります。大々的にというよりは、さりげない訴求がポイントです。`,
    },
    sns: {
      point_1: `「本日の日替りランチ」を SNSで配信`,
      point_content_1: `フェイスブックやLINE＠を使って、出勤時刻にランチメニューをアプローチすれば、お客様の来店動機に繋がります。`,
      point_2: '雨の日などは集客のチャンス',
      point_content_2: `どうしても客足が遠のく雨の日は｢雨の日ｷｬﾝﾍﾟｰﾝ｣など雨の日だけのサービスをリアルタイムで配信。 他店との差別化をはかって集客につなげます。`,
      point_3: `イチオシ情報を事前告知
      する事で予約増をサポート`,
      point_content_3: `「売り切れ御免の限定メニュー」など限定食材や季節のフェアをSNSで配信。 先々の予約を獲得できるので、安定した集客を見込めます。`,
    },
    contact: {
      header: `メニューエクスプレスにご関心をお持ちいただきまして、ありがとうございます。ご意見・ご要望など、お気軽にお問合わせください。
      各項目を入力していただき、入力が終わりましたら入力内容の確認ボタンを押してください。`,
      item_entry: '項目の入力',
      confirmation: '入力内容の確認',
      transmission: '送信完了',
      inquire_type: 'お問い合わせ種別',
      your_name: 'お名前',
      furigana: 'フリガナ',
      email: 'メールアドレス',
      inquire_details: 'お問い合わせ内容',
      required: '必須',
      email_format: '（***@menuexpress.jpから受信できる必要があります）',
      policy: '<0>個人情報保護方針</0>を一読のうえ、確認画面へお進みください。',
      confirm_entry: '入力内容を確認する',
      submitted: `お問い合わせが送信されました。
      回答をお待ちください。`,
      return: 'トップページに戻る',
      back: '戻る',
      send: '送信する',
      options: 'ご意見・ご要望',
      about_defects: '不具合について',
      others: 'その他',
      placeholder_name: '例 ）山田 花子',
      placeholder_furigana: '例 ）ヤマダ ハナコ',
      placeholder_email: '例 ）example@example.com',
    },
    gm_templates: {
      size: 'サイズ',
      atmosphere: '雰囲気',
      quantity: '数量',
      keyword_tags: 'キーワード（タグ）',
      font: 'フォント',
      tags: 'タグ',
      status: 'ステータス',
    },
    gm: {
      landing: `ナビゲートするオプションがあります。
      このポータルから MEX for WEB または MEX for GM にアクセスします。`,
      click_here_tooltip_gm: 'グランドメニューを作成するにはここをクリックしてください',
      navigate_to_web_or_gm: `別のページに移動しようとしています。

        記入中の入力フォームについて\nこのページ上の保存されていない変更は\n失われる可能性があります。`,
      do_you_wish_to_continue: '続行しますか?',
      cancel_confirmation: '注文をキャンセルしてもよろしいですか？',
      click_here_tooltip_web: 'オンラインでテンプレートからメニューを作成する場合はこちら',
      font_type: 'フォントの種類',
      hashtags: 'ハッシュタグ',
      notes: 'ノート',
      register_hashtags: 'ハッシュタグを登録する',
      create_menu_request: 'メニュー作成リクエスト',
      template_details: 'テンプレートの詳細',
      un_favorite: 'お気に入りリストから削除します。よろしいですか?',
      manuscript: '原稿',
    },
    accept_order: {
      already_assigned: 'メニューオーダーはすでに別のデザイナーに割り当てられています。',
      already_closed:
        'メニューの注文はすでに締め切られています。注文を受け付けることはできません。',
      already_cancelled:
        'メニュー注文はすでにキャンセルされています。注文を受け付けることはできません。',
      not_found: 'メニューの順序を取得できません。',
      return_to_menu: 'メニューの注文に戻る',
    },
    gm_menu_creation: {
      view_menu_creation: 'メニュー作成リクエストの詳細を表示',
      category: 'カテゴリー',
      atmosphere: '雰囲気',
      font: '文字タイプ',
      no_of_items: 'アイテム数(メニュー数)',
      size: 'サイズ',
      type: 'タイプ',
      manuscript: '原稿',
      template: 'テンプレート',
      payment_amount: 'お支払い金額',
      select_manuscript: '原稿を選択',
      filter_by_status: 'ステータスのフィルター',
      select_existing_manuscript: '既存の原稿を選択してください',
      create_new_manuscript: '新規原稿作成',
      create_new_manuscript_button: '新しい原稿の作成',
      selected_manuscript: '選択された原稿',
      selected_manuscript_and_template: '原稿とテンプレートを選択',
      select_category: 'カテゴリの選択',
      required: '必須',
      next: '次へ',
      select_atmosphere: '雰囲気の選択',
      select_font_type: 'フォントタイプの選択',
      select_items: 'アイテム数の選択',
      select_size: 'サイズの選択',
      select_type: 'ページタイプの選択',
      select_template: 'テンプレートを選択',
      proceed_order: '注文の確認へ進む',
      single_sided_price: '片面価格',
      price_one_sided: '※価格は片面×2の価格です',
      menu_order_management: 'メニュー作成・注文管理',
      category_step: '業態',
      atmosphere_step: '雰囲気',
      font_step: '文字タイプ',
      no_of_items_step: 'アイテム数',
      size_and_finish_step: 'サイズ',
      type_step: 'タイプ',
      manuscript_step: '原稿',
      template_step: 'テンプレート',
      confirm_order_step: '注文を確認する',
      payment_step: '支払い',
      proceed_payment: '支払いに進む',
      payment_done: '支払い完了',
      done: '完了',
      payment_done_note: 'お支払い方法が正常に登録され決済が完了しました。',
      go_to_menuorder: 'メニュー作成注文リストに移動',
      payment_method: '支払方法',
      allowed_max_pages: '最大30ページまで。',
      allowed_payable_amount: '支払額は50万円を超えないこと。ページ数の変更をお願いします。',
    },
    gm_templates_filter: {
      new_arrivals: '新着',
      popularity: '人気',
    },
  },
};

export default ja;
