const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email_format: 'Please input correct email address format.',
      email: 'Please input correct email format.',
      password: {
        minLength: 'Password must be 8~20 in length.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
      positive_num: 'This field must be a positive number.',
      image: {
        invalid_type: 'Not a valid file format.',
        invalid_type_with_size:
          'Invalid image input. Image dimensions must be 1236x717 and file size should not be greater than 5MB.',
        unsupported_file_format: `Please upload the file again in a supported file format.
        Supported formats: JPEG, PNG, JPG.`,
      },
      name: {
        max: 'The field must not be greater than 255 characters.',
      },
      date: {
        invalid_prev_date: 'The selected date and time must be current or onwards.',
      },
      katakanaOnly: 'Please enter furigana in katakana',
      email_max: 'The field must not be greater than 50 characters.',
      details_max: 'The field must not be greater than 50 characters.',
      template: {
        duplicate: 'A template with the same name has been registered.',
      },
      chat: {
        required: 'Please type a message to continue.',
        max: 'The field must not be greater than 250 characters.',
        redirect: 'This will be redirected to Menu Order Management Page.',
      },
      attachment: {
        max: 'Attachments selected is more than 5',
        invalid:
          'File type must be one of the following: .doc, .docx, .txt, .xlsx, .png, .jpg, .jpeg, and .pdf and file must not exceed 2MB. Please upload valid file(s) and try again.',
      },
      final_offer: {
        max: 'Attachments selected is more than 5',
        invalid:
          'File type must be one of the following: .png, .jpg, .jpeg, and .pdf and file must not exceed 2MB. Please upload valid file(s) and try again.',
      },
      file: {
        size: 'File size is too large',
        format: 'Unsupported file format',
      },
      number: {
        invalid: 'Please enter a valid number.',
        min: 'The number must be at least {{value}}.',
        max: 'The number must not exceed {{value}}.',
      },
      string: {
        max: 'The field must not exceed {{max}} characters.',
      },
      long_message: {
        max_textfield: 'The message is too long.',
      },
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      login: 'Login',
      signup: 'Sign Up',
      signin: 'Sign In',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Password?',
      email_address: 'Email Address',
      email: 'Email',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
      welcome: 'Welcome,',
      menu: 'Menu',
      templates: 'Templates',
      contact: 'Contact',
      terms_privacy: 'Terms and Conditions • Privacy Policy',
      register_free: 'Register Free',
      logout_message: 'Would you like to logout?',
      create: 'Create New',
      edit: 'Edit',
      delete: 'Delete',
      deleted: 'Deleted',
      start_editing: 'Start Editing',
      tool_tip: 'Please log in to add favorites.',
      group_template: 'Group Template (MEX for WEB)',
      select_template: 'Template',
      cancel_modal: 'No, Cancel',
      confirm_modal: "Yes, I'm Sure",
      select: 'Please Select',
      upload_image: 'Upload image',
      no_file_chosen: 'No file chosen',
      no_data: 'No data.',
      all: 'All',
      menu_details: 'Menu Design Details',
      close: 'Close',
      public: 'Public',
      private: 'Private',
      insert_text: 'Insert Text',
      register: 'Register',
      renew: 'Renew',
      view: 'View',
      done: 'Done',
      about: 'About Us',
      terms_of_use: 'Terms of Use',
      try_web_version: `Try to use
      WEB version`,
      try_mobile_version: `Download from
      App Store`,
      about_web_version: 'About the Web version',
      about_mobile_version: 'About Download',
      back: 'Back',
      hour: 'hour',
      hours: 'hours',
      minutes: 'minutes',
      minute: 'minute',
      ago: 'ago',
      notifications: 'Notifications',
      print: 'Print Order',
      pdf: 'PDF Download',
      service: 'Service',
      mex_for_gm: `MEX for GM`,
      templates_for_gm: 'Templates for GM',
      templates_for_web: 'Templates for WEB',
      mex_for_web: 'MEX for WEB',
      confirm: 'Confirm',
      download_csv: 'Download as CSV',
      menu_order_management: 'Menu Order Management',
      request_no: 'Request No.',
      designer: 'Designer',
      customer: 'Customer',
      thumbnail: 'Thumbnail',
      date_requested: 'Date Requested',
      proceed: 'Proceed',
      copy: 'Copy',
      initializing: 'Initializing',
      loading: 'Loading',
      menu_order: 'Menu Order',
      optional: 'Optional',
      select_option: 'Select',
      card_number: 'Card Number',
      expiration_date: 'Expiration Date',
      security_code: 'Security Code',
      card_name: 'Card Name',
      refund: 'Refund',
      download_all: 'Download All',
      template_type: 'Template Type',
      standard: 'Standard',
      pro: 'Pro',
      pro_2: 'Pro 2',
      try_menu_express: 'Try Menu Express',
      admin: 'Admin',
      menu_request_un_auth: `You logged in as an administrator.
      You do not have access to create menu orders.`,
      menu_request_pro_auth: `You are logged in as Pro User.
      Transaction will be terminated.`,
      menu_express_for_web: `Menu Express for Insert Menus (Online creation and creation requests are possible)`,
      menu_express_for_gm: `Menu Express for Grand Menus (Select a template and request creation)`,
      base_business: `Based on business type and atmosphere
      Narrow down the menu
      and atmosphere.`,
      show_previous_revision: 'Show Previous Revision',
      design_approval: 'Design Approval',
      design_revision_request: 'Design Revision Request',
      accepted: 'Accepted',
      order_menu: 'Photograph the Insert Menu',
      attach_data: 'Attach Data',
      upload_design: 'Submit',
      supplemental_text: 'Supplemental Text',
      instructions: 'Fill in the creation contents instruction (price, hope, etc. *required)',
      surface_finish: 'Surface Finishing',
      with_pouch: 'With pouch',
      without_pouch: 'Without pouch',
      delivery_type: 'Delivery Type',
      store: 'Store',
      branch_office: 'Branch Office',
      go_to_shipping_input: 'Go to Shipping Address Information Entry',
      print_request: 'Menu Creation Request',
      shipping_name: 'Distribution First Name',
      business_phone_number: 'Business Phone Number',
      delivery_postal_code: 'Shipping Postal Code',
      delivery_prefecture1: 'Shipping Address',
      delivery_prefecture2: 'Administrative Divisions of Japan',
      shipping_address: 'Address',
      delivery_request_date: 'Delivery Request Date',
      print_request_count: 'Number of copies requested to be printed',
      order_completed: 'Order has been completed.',
      order_completed_message_1: `A completion notification will be sent after the design is created.`,
      order_completed_message_2: `Please check the design from the URL shown in the notification and click “Approve” or “Revise” to submit your order.`,
      order_completed_message_3: `If there is no reply to the completion notification within one week, the application will be automatically approved.`,
      order_number: 'Order No.',
      approve_design: 'Approve Design',
      revision_request: 'Revision Request',
      show_previous_revisions: 'Show Previous Revisions',
      no_revisions: 'No modifications have been made so far.',
      send_revision: 'Send Revision',
      delivery_details: 'Delivery Details',
      revision_number: 'Revision No.',
      accept_revision_message: 'Do you approve the newly updated design?',
      order_success: 'Your order has been successfully accepted.',
      select_revision_download: 'Select Revision to Download',
      download: 'Download',
      go_back_to_gohanju_navi_app: 'Go back to Gohanju Navi App',
      pro_en: 'PRO',
      narrow_by_group: 'Narrow by group (brand, use, etc.)',
      contact_the_business_store: 'Contact the business store',
      No_file_available: 'No file available.',
    },
    pages: {
      login: {
        signup: {
          description: 'Do you have an account?',
        },
        invalid_auth: 'Incorrect email address or password.',
      },
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        sub_heading: 'To recover your account, please enter your email address below.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      templates: {
        template_list: 'Template List',
        name: 'Name',
        design: 'Design',
        menu: 'Menu',
        group: 'Group',
        disclosure: 'Disclosure',
        create: 'Create New',
        search: 'Search Keyword (ID, Name, Business Categories, Type)',
        designs: 'Designs',
        all: 'All',
        narrow_down: 'Narrow Down',
        refine_narrow_down: 'Refine/Narrow Down',
        sort: 'Sort By',
        new: 'New',
        old: 'Old',
        edit_register_template: 'Edit/Register Template',
        template_name: 'Template Name',
        group_tab: 'Group Tab',
        display_order: 'Display Order',
        template_image: 'Template Image',
        thumbnail_image: 'Thumbnail Image',
        edit_template: 'Edit Template',
        register_template: 'Register Template',
        jp: 'Japanese',
        en: 'English',
        name_already_used: 'Name already existed cannot be used.',
        multiple_select_possible: 'Multiple selection possible.',
        display_sort_order:
          'They will be sorted in this numerical order in the template list (if not specified, they will be at the end).',
        delete_template_confirmation: 'Are you sure you want to delete this template?',
        no_group_tabs: 'No available group tabs for the selected group.',
        delete_confirmation: 'Are you sure you want to delete this template?',
        template_deleted_message: 'Template has been deleted.',
        template_updated_message: 'Template details have been updated.',
        menu_design_deleted_message: 'Menu Design has been deleted.',
        open_editor: 'Open editor',
        business_categories: 'Business Categories',
        business_type: 'Business Type',
        type: 'Type',
        saving_details: 'Saving template details.',
        template_in_use: 'Cannot be deleted. The template is currently in used',
      },
      groups: {
        renew: 'Renew',
        group_list: 'Group List',
        group_id: 'Group ID',
        group_name: 'Group Name',
        login_id: 'Login ID',
        login_password: 'Login Password',
        membership_limit: 'Membership Limit',
        no_registered_members: 'No. of Registered Members',
        template: 'Template',
        max_number_of_member: 'Max number of Members',
        number_of_registered_member: 'Number of Registered Members',
        tab: 'Tab',
        delete_confirmation: 'Are you sure you want to delete this group?',
        group_tab: 'Group Tab',
        create_new: 'Create New',
        group_edit: 'Group Edit',
        eye_catching_image: 'Eye-catching image 1236 x 717',
        eye_catching_url: 'URL for eye catching',
        group_deleted_message: 'Group has been deleted.',
      },
      notifications: {
        title: 'Title',
        message: 'Message',
        notification_list: 'Notification List',
        notification_deleted_message: 'Notification has been deleted',
        notification_category: 'Notification Category',
        schedule_transmission: 'Schedule date of transmission',
        delete_confirmation: 'Are you sure you want to delete notification?',
        create_notification: 'Create Notification',
        edit_notifification: 'Edit Notification',
        registration: 'Register',
        schedule_reservation_date: 'Schedule reservation date',
        schedule_message:
          'After this date and time, this message will be reserved and sent to the reserved devices in sequence. Once reserved, it cannot be changed.',
        category: {
          normal: 'Normal',
          important: 'Important',
          news: 'News',
        },
        email: {
          title: 'Send email notification',
        },
        search: 'Search Keyword',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Account Information',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Restaurant menus are',
        sub_heading: 'Free and easy to make',
        why_heading: 'Why use Base Template?',
        info_message: `The WEB version is
        now available for
        both web and
        Android!`,
        operation: {
          header: 'EASY OPERATION',
          title: `Easy to operate!
          Create a menu in just
          3 minutes`,
          description:
            'You can create professionally designed restaurant menus by simply taking a photo with your PC or smartphone, selecting from more than 600 professionally designed templates, and entering text. *It supports a variety of business categories such as izakaya, cafes, bars, bars, Chinese food, and more.',
          btnlbl: '→ For first time customers',
        },
        troubleshooting: {
          header: 'TROUBLE SHOOTING',
          title: `Solve your problems
          with menu creation and
          attracting customers !`,
          title_two: `You can create your original menu while consulting directly with the designer!`,
          description:
            'The app has a printing function, so you can decorate the front of your restaurant or the inside of your restaurant. Furthermore, since it is equipped with a SNS share function, it is also effective in attracting customers by spreading the word through SNS.',
          description_two: `In addition to the ability to edit your own design using a template, you can also request a designer to create a menu design for you. After the request is made, you can communicate with the designer in real time via the in-page chat function to get closer to your ideal design.`,
          btnlbl: '→ What is menu express?',
        },
        template_list: '→ Template List',
        quality_title: {
          first_title: 'created by professionals',
          second_title: '600 high-quality designs',
        },
        quality_description: `New templates are added every month, so you're sure to find the template you're looking for.`,
        contribution: {
          first_title: 'Effective Usage Scenes ',
          second_title: 'Menu Express contributes to the prosperity of your restaurant!',
          third_title: 'Guide to effective utilization',
          description: `Menu Express is all about the positives for your restaurant.
          Here are some effective ways to use it.`,
        },
        btn_details: 'See details →',
      },
      about: {
        web_heading: 'What is Menu Express for WEB?',
        web_heading_pro2: 'What is Menu Express for WEB PRO Edition?',
        gm_heading: 'What is Menu Express for GM (Grand Menu)?',
        web_content:
          'It is a service that allows you to easily create your own menu inserts online.',
        gm_content: `This is a service that allows customers to select their own images online and easily request a designer to create a menu for them.`,
        main_heading: 'For First-Time Customers',
        page_title: 'What is Menu Express?',
        page_definition:
          'This is a useful service that solves restaurant issues! Are there any issues or concerns about your restaurant?',
        banner_gm_first_title: `This is a service to create a grand menu for your 
        restaurant “easily” and “inexpensively”`,
        banner_first_title: 'Original menu on your smartphone',
        banner_second_title: 'Easy to create in seconds',
        gm_banner_descriptions_one: `A service to help you solve your restaurant issues!
        Do you have a “problem or concern” in your restaurant?
        The menu is a part of your staff.
        We use menus to quickly solve the day-to-day problems and issues your restaurant faces.
        We can quickly solve your daily problems and issues by using menus.
        We can help you solve the problems of “I am not confident to make it by myself...” and “I am not confident to make it by myself.”
        We can also solve problems such as “I'm not confident to make it by myself!`,
        gm_banner_descriptions_two: `Menu Express for GM allows you to
        Just select the category that best fits your store from among the many business categories.
        and choose a template from the list.
        After that, you can communicate directly with the designer via chat,
        and then you can directly communicate with the designer in a chat format to create your ideal image!`,
        banner_descriptions: `Once you have decided where and for what purpose you will use the
          menu, all you need to do is 4 easy steps.
          It is fully equipped with functions such as output service, SNS posting,
          and image correction.
          Find your favorite from a wide variety of menu templates.
          Because the food is made with care,
          Why not be particular about your menu?`,
        banner_descriptions_pro2: `Once you've decided where and for what purpose to use the menu,
          the rest is just 4 simple steps.
          Find your favorite from the wide variety of menu templates.
          Then leave it to the designer!
          Since you're putting care into making your dishes,
          put the same care into your menu...`,
        footer_description: `First, use the web version to
        make your store more attractive.`,
        footer_btn_first_label: 'Try to use',
        footer_btn_second_label: 'WEB version',
        steps: {
          take_photo: 'Take a photo ',
          process_photo: `Process the photos
          you took`,
          enter_characters: `Enter characters
          and amounts`,
          complete: `Complete!
          Print or disseminate
          information by posting
          on SNS!`,
        },
        gm_steps: {
          step_one: `Select items from the 
          categories that closely 
          resemble your image`,
          step_two: `Select fonts and number of pages`,
          step_three: `Chat with a designer 
          to discuss your image`,
          step_four: `The design is completed!
          You can print it yourself or order printing as is!
          You will get the actual
          menu in a few days!`,
        },
        pro2_steps: {
          step_one: `Select your preferred
                  template`,
          step_two: `Attach the photos
                  or images you want
                  to add to the template`,
          step_three: `Enter text, prices,
                  and the number of copies,
                  then send to the designer`,
          step_four: `Done!
                  Delivered to your
                  registered address!`,
        },
        effects: {
          title: 'Examples of introduction effects',
          increase_price: {
            title: 'Increase in average spend per customer',
            description:
              'By using the "one more item" approach, such as a finishing dish or dessert after a meal, the average spend per customer increased compared to before creating the POP.',
          },
          cost_down: {
            title: 'Cost rate down',
            description:
              'By effectively utilizing excess inventory to prevent food waste and using a POP approach to menus with high profit margins, we were able to lower the cost rate and increase profits even with the same sales as before.',
          },
          increase_repeat: {
            title: 'Increase repeat rate',
            description:
              'By promoting pre-ordered banquet menus using rare ingredients to all tables through POP, we have increased the number of people making reservations for their next visit when they visit the restaurant, which was not the case before.',
          },
          increase_sales: {
            title: 'Increase in sales and branding',
            description:
              'By increasing the number of contact points in front of and inside the restaurant, the recommended menu items have become the "signature menu item" that most customers order.',
          },
        },
        how_to_use: {
          title: 'Examples of how to use it',
          food_loss: {
            title: 'Food loss cut',
            description:
              'When there is excess stock of ingredients due to variations in the number of items served, etc., you can prevent ingredient loss by creating and promoting new menus and assorted POPs that are not on the regular menu.',
          },
          opportunity_loss: {
            title:
              'Preventing opportunity loss by attracting customers stably even during slow periods',
            description:
              'Even when the number of customers usually decreases, such as on rainy days, you can stably attract customers by distributing POP created using templates such as "Rainy Day Campaign" on SNS in real time.',
          },
          improving_operations: {
            title: 'Improving "last mile" operations',
            description:
              'Rather than viewing the food used when creating POP as waste, hall staff can eat it and deepen their understanding of the taste and characteristics of the food, allowing them to properly guide customers about the food and improving operations.',
          },
        },
      },
      manuscripts: {
        title: 'Manuscript Management',
        headers: {
          id: 'ID',
          name: 'Manuscript Name',
          file: 'File',
          attachments: 'Image Attachments',
          created_at: 'Date Created',
          action: 'Action',
        },
        create_manuscript: 'Create Manuscript',
        manuscript_name: 'Manuscript Name',
        ocr: 'OCR',
        upload_file: 'Upload File',
        open_camera: 'Open Camera',
        description: 'Description',
        remarks: 'Remarks',
        confirm_delete: 'Are you sure you want to delete this manuscript?',
        required: 'required',
        manuscript_in_use: "You can't delete the manuscript. It is currently in use.",
        name_in_use:
          'The manuscript name you have entered is already in use. Please choose a different name.',
        attachment: 'Attachment',
        invalid_attachment:
          'File type must be one of the following: .png, .jpg, .jpeg, and .gif and file must not exceed 4MB. Please upload valid file(s) and try again.',
      },
      chat: {
        title: 'Communication',
        files: {
          offers: 'Please find the attached final revision below.',
          manuscripts: 'Please find the attached files below.',
        },
        additional_fee: {
          request: 'Request For Additional Fee',
          create: 'Create Additional Fee',
          paid: 'Paid',
          un_paid: 'Unpaid',
          remarks: 'Remarks',
          amount: 'Amount',
          create_button: 'Create',
          success_create: 'Additional fee successfully created.',
          amount_validation: 'The amount cannot be less than 100円',
          confirm_payment: 'Confirm Payment',
          additional_amount: 'Additional Amount',
          proceed_payment: 'Proceed Payment',
          pay: 'Pay',
          payment_notice: 'Once the payment has been made, it will not be refundable.',
          request_id: 'Request ID',
          status: 'Status',
          payment_note:
            '*Payment will be made with the credit card registered at the time of booking confirmation.',
          refund_note: 'Are you sure you want to refund additional fee?',
          cancel_note: 'Are you sure you want to cancel the request?',
          cancelled: 'Cancelled',
          refunded: 'Refunded',
          cancelled_success: 'Additional fee successfully cancelled',
          refund_success: 'Additional fee successfully refunded',
          already_refunded: 'The fee has already been refunded',
          payable_amount: 'The amount of payment shall not exceed 500,000 yen.',
        },
        offer: {
          checkbox: "Send revision to customer's email address",
          button: 'SEND FINAL REVISION',
          request_fee: 'REQUEST FOR ADDITIONAL FEE',
          pay_additional_fee: 'PAY ADDITIONAL FEE',
          complete: {
            label: 'You can no longer reply to this conversation.',
            button: 'View Final Review File in the Menu Order Details',
          },
          upload: {
            title: 'Send Final Revision',
            label: 'You may choose multiple files.',
            button: 'Upload',
          },
          sent: {
            success: 'Final Offer Sent.',
            failed: 'Failed to send final offer.',
          },
        },
      },
      design: {
        deleted: 'The menu design has been deleted.',
      },
    },
    menu: {
      home: 'Home',
      about: 'About Service',
      template_list: 'Template List',
      user_guide: 'User Guide',
      contact_us: 'Contact Us',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      my_page: 'MyPage',
      history: 'History',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
      templates: 'Template Management',
      designs: 'Menu Design Management',
      groups: 'Group Management',
      last_update: 'Last update',
      days: 'day(s) ago',
      months: 'month(s) ago',
      years: 'year(s) ago',
      today: 'today',
      guide: 'List of Effective Application Scenes',
      counter: 'Effective Storefront Presentation',
      store: 'Effective In-store Staging',
      sns: 'Effective use of SNS',
      templates_back: 'Back to Template List',
      account_information: 'View/Edit Account Information',
      change_password: 'Change Password',
      cancel_membership: 'Cancel Membership',
      notifications: 'Notification Management',
      chat: 'Chat',
      order_management: `Order Management`,
      manuscript_management: 'Manuscript Management',
      menu_creation_order_management: 'Menu Creation Order Management',
      menu_creation_request_history: 'Menu Creation Request History',
      missing_template: 'The chosen template has been removed. Kindly choose a different template.',
      missing_manuscript:
        'The chosen manuscript has been removed. Kindly choose a different manuscript.',
      missing_both:
        'The chosen template and manuscript have been removed. Kindly choose a different manuscript and template.',
      select_template: 'Select Template',
      select_manuscript: 'Select Manuscript',
      select_both: 'Select Manuscript and Template',
      custom_page_error: 'The custom page must be less than or equal to {{value}}.',
      price_error: 'The price must be less than or equal to {{value}}.',
    },
    table: {
      no_data: 'No records found.',
    },
    select: {
      options: {
        general: 'General',
        all: 'All',
      },
    },
    template: {
      filters: 'Filters',
      search_template: 'Search templates',
      design_found: 'design found', // 1 design found
      designs_found: 'designs were found', // 2 or more designs found
      design_empty: 'There are no applicable templates.',
      business_categories: {
        narrow_down: 'Narrow down business categories',
        all: 'All',
        izakaya: 'Izakaya',
        japanese_food: 'Japanese food, sushi, seafood dishes',
        udon_soba: 'Udon Soba',
        eel: 'Eel',
        yakitori: 'Yakitori',
        yakinikum_offal: 'Yakiniku, offal',
        tonkatsu: 'Tonkatsu and shabu-sabu',
        kushiage: 'Kushiage/Tempura',
        okonomiyaki: 'Okonomiyaki and Monjayaki',
        okinawan: 'Okinawan cuisine',
        western_food: 'Western food/French/Italian',
        steak_hamburger: 'Steak Hamburger',
        chinese_food: 'Chinese food/Korean food',
        curry_thai_food: 'Curry/Thai food',
        cafe: 'Cafe/Bar',
        others: 'Others',
      },
      refine: {
        narrow_type: 'Narrow down Type',
        all: 'All',
        vertical_type: 'Vertical Type',
        horizontal_type: 'Horizontal Type',
        daily_recommended: 'Daily/Recommended',
        limited_menu: 'Limited menu',
        seasonal_menu: 'Seasonal Menu',
        morning: 'Morning',
        lunch: 'Lunch',
        dinner: 'Dinner',
        luxury: 'Luxury',
        the_masses: 'The Masses',
        all_you: 'All-you-can-drink/All-you-can-eat',
        year_end: 'Year-end party/welcome and farewell party',
        banquet_course: 'Banquet/course meal',
        drink: 'Drink',
        take_out: 'Take-out',
        others: 'Others',
      },
      menu_management: {
        popularity: 'Popularity',
        last_10_days: 'Last 10 days',
        newest_first: 'Newest First',
        oldest_first: 'Oldest First',
        favorites: 'Favorites',
        editing_and_edited: 'Editing and Edited',
        last_update_30: 'Last update 30min ago',
        last_update_8: 'Last update 8 hours ago',
        exceed_refund: 'The refund amount cannot exceed the purchase amount.',
        refund_amount: 'Refund Amount',
      },
    },
    guide: {
      heading: 'Information and services aimed at effective store presentation',
      sub_heading: 'Full range of services and functions!',
      counter_content: `The presentation of the storefront and the appeal of the food is one of the most important factors in a customer's choice of restaurant.`,
      store_content:
        'Inside the store you can address your issues and concerns using tabletop and wallpaper.',
      sns_content:
        'Menus created with Menu Express can be distributed in real time via SNS, making it an effective tool for attracting customers and acquiring reservations for banquets and other events.',
      templates_heading: `By order a designer,`,
      templates_content: `We can design a menu that matches the atmosphere of the your store and is full of originality!`,
    },
    counter: {
      point_1: 'Is the menu designed to give an idea of each dish?',
      point_content_1:
        'A menu with only text cannot convey the image of the food. By using a menu with photos, the image of the food can be conveyed even more clearly. Photos of food are especially important for storefront menus, as it is not possible to ask the staff about the menu.',
      point_2: 'You want people to know about your restaurant',
      point_content_2:
        'In addition to food templates, you can also provide information about your restaurant, such as holidays, hours of operation, non-smoking and smoking restrictions, etc., at the storefront to convey information about your restaurant and to motivate customers to visit.',
    },
    store: {
      point_1: 'The wall there, is there any reason not to use it?',
      point_content_1: `Do you know the place that is looked at the most, albeit casually? It is the wall surface around the tables. How about displaying POP of banquet or girls' party to attract reservations for the next time, or promoting a closing dish to increase the customer spend?`,
      point_2: 'It is also important to keep customers from getting bored.',
      point_content_2: `The menu changes daily with dishes using seasonal ingredients. Such a recommended menu is attractive, isn't it? However, changing the menu every day requires a lot of time and effort, but if you use a menu board that is easy to replace, there is no stress on site even if you change the menu every day. It is effective for promoting killer contents separately from the grand menu.`,
      point_3: 'We will bring you together.',
      point_content_3: `It will connect you with the right people. If you use a template for recruiting staff and have your store's fans and their friends and acquaintances apply for the position, you can reduce recruiting costs. The key is to make a subtle appeal rather than a big one.`,
    },
    sns: {
      point_1: `Distribute "Today's Daily Lunch" on SNS`,
      point_content_1: `Using Facebook and LINE@ to approach lunch menus during work hours will motivate customers to visit the restaurant.`,
      point_2: 'Rainy days and other opportunities to attract customers',
      point_content_2: `On rainy days, when customers are inevitably turned away, you offer "rainy day campaigns" and other services that are only available on rainy days in real time. This will help to differentiate your restaurant from others and attract customers.`,
      point_3:
        'Supporting increase in reservations by announcing recommended information in advance',
      point_content_3: `You can distribute information on limited-edition foods and seasonal fairs, such as "limited menu items that are sure to sell out," on SNS. This enables you to obtain reservations ahead of time, and thus can expect to attract a steady stream of customers.`,
    },
    contact: {
      header: `Thank you for your interest in Menu Express. Please feel free to contact us with any comments or requests.
      Please fill in the form below and click the "Confirm your entry" button when you are finished.`,
      item_entry: 'Item Entry',
      confirmation: 'Confirmation of input contents',
      transmission: 'Transmission complete',
      inquire_type: 'Inquiry Type',
      your_name: 'Your Name',
      furigana: 'Furigana',
      email: 'Email Address',
      inquire_details: 'Inquiry Details',
      required: 'Required',
      email_format: '(must be able to receive from ****@menuexpress.jp)',
      policy: 'Please read our <0> Privacy Policy </0> before proceeding to the confirmation page.',
      confirm_entry: 'Confirm your entry',
      submitted: `Your inquiry has been submitted.
      Please wait for our response.`,
      return: 'Return to the top page',
      back: 'Go back',
      send: 'Send',
      options: 'Opinions and Requests',
      about_defects: 'About Defects',
      others: 'Others',
      placeholder_name: 'Example: Hanako Yamada',
      placeholder_furigana: 'Example: ヤマダ ハナコ',
      placeholder_email: 'Example: example@example.com',
    },
    gm_templates: {
      size: 'Size',
      atmosphere: 'Atmosphere',
      quantity: 'Quantity',
      keyword_tags: 'Keyword (tag)',
      font: 'Font',
      tags: 'Tags',
      status: 'Status',
    },
    gm: {
      landing: `You have the option to navigate
      MEX for WEB or MEX for GM from this portal`,
      click_here_tooltip_gm: 'Click here to create a grand menu',
      navigate_to_web_or_gm: `You are about to navigate to another page
      to complete an input form.
      Any unsaved changes on this page
      may be lost.`,
      do_you_wish_to_continue: 'Do you wish to continue?',
      cancel_confirmation: 'Are you sure you want to cancel order?',
      click_here_tooltip_web: 'Click here to Create Menu using Web Version',
      font_type: 'Font Type',
      hashtags: 'Hashtags',
      notes: 'Notes',
      register_hashtags: 'Register Hashtags',
      create_menu_request: 'Create Menu Request',
      template_details: 'Template Details',
      un_favorite: 'Are you sure you want to remove this to your favorite list?',
      manuscript: 'Manuscript',
    },
    accept_order: {
      already_assigned: 'Menu order has already been assigned to another designer.',
      already_closed: 'Menu order is already closed. Accepting order is not possible.',
      already_cancelled: 'Menu order is already cancelled. Accepting order is not possible.',
      not_found: 'Unable to retrieve menu order.',
      return_to_menu: 'Return to Menu Orders',
    },
    gm_menu_creation: {
      view_menu_creation: 'View Menu Creation Request Details',
      category: 'Category',
      atmosphere: 'Atmosphere',
      font: 'Font',
      no_of_items: 'No. of Items',
      size: 'Size and Finish',
      type: 'Type',
      manuscript: 'Manuscript',
      template: 'Template',
      payment_amount: 'Payment Amount',
      select_manuscript: 'Select Manuscript',
      filter_by_status: 'Filter by Status',
      select_existing_manuscript: 'Select an existing manuscript',
      create_new_manuscript: 'Create a new manuscript',
      create_new_manuscript_button: 'Create New Manuscript',
      selected_manuscript: 'Selected Manuscript',
      selected_manuscript_and_template: 'Select Manuscript and Template',
      select_category: 'Select Category',
      required: 'Required',
      next: 'Next',
      select_atmosphere: 'Select Atmosphere',
      select_font_type: 'Select Font Type',
      select_items: 'Select No. of Items',
      select_size: 'Select Size',
      select_type: 'Select Type',
      select_template: 'Select Template',
      proceed_order: 'Proceed to Confirm Order',
      single_sided_price: 'Single Sided Price',
      price_one_sided: '*Prices are for one side x 2',
      menu_order_management: 'Menu Creation Order Management',
      category_step: 'Category',
      atmosphere_step: 'Atmosphere',
      font_step: 'Font Types',
      no_of_items_step: 'No. of Items',
      size_and_finish_step: 'Sizes',
      type_step: 'Types',
      manuscript_step: 'Manuscripts',
      template_step: 'Templates',
      confirm_order_step: 'Confirm Order',
      payment_step: 'Payments',
      proceed_payment: 'Proceed to Payment',
      payment_done: 'Payment Done',
      payment_done_note:
        'Payment method has been successfully registered and payment has been completed.',
      go_to_menuorder: 'Go to Menu Orders List',
      payment_method: 'Payment Method',
      done: 'Done',
      allowed_max_pages: 'Only maximum 30 pages is allowed.',
      allowed_payable_amount:
        'The amount of payment shall not exceed 500,000 yen. Please change the number of pages.',
    },
    gm_status_filter: {
      open: 'OPEN',
      in_progress: 'IN PROGRESS',
      complete: 'COMPLETED',
      cancel: 'CANCELLED',
      closed: 'CLOSED',
    },
    gm_templates_filter: {
      new_arrivals: 'New Arrivals',
      popularity: 'Popularity',
    },
  },
};

export default en;
