/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette = {
  yellow: '#FFC000',
  brown: '#7A5F4E',
  lightBrown: '#F5F2ED',
  black: '#131313',
  darkGray: '#757575',
  lightGray: '#D3D3D3',
  brightGray: '#D9D9D9',
  blue: '#034EA1',
  lightBlue: '#1D3BF8',
  gray: '#707070',
  gray2: '#B4B4B4',
  online: '#4EA72E',
  offline: '#929292',
  primary: {
    main: '#F58030',
    contrastText: '#fff',
  },
  secondary: {
    main: '#D14081',
    contrastText: '#fff',
  },
  success: {
    main: '#0BAC00',
    contrastText: '#fff',
  },
  error: {
    main: '#FF0000',
    contrastText: '#fff',
  },
  warning: {
    main: '#F3C969',
    contrastText: '#fff',
  },
  info: {
    main: '#9DD1F1',
    contrastText: '#fff',
  },
  transparent: {
    main: '#fff',
    dark: '#888',
  },
  divider: 'rgba(0,0,0,0.05)',
};

export default palette;
