import { configureStore } from '@reduxjs/toolkit';
import landReducer from '../global/reducer';
import menuReducer from '../menuOrders/reducer';
import searchMenuReducer from '../menuOrders/search';

const store = configureStore({
  reducer: {
    lang: landReducer,
    menuOrders: menuReducer,
    searchMenuOrder: searchMenuReducer,
  },
});

export default store;
