import { roles } from 'config/userRole';

const routes = [
  {
    path: '/admin/templates',
    component: 'pages/authenticated/admin/template',
    navbar: 'dashboard',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/templates/create',
    component: 'pages/authenticated/admin/template/AddEditTemplate',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/templates/edit/:id',
    component: 'pages/authenticated/admin/template/AddEditTemplate',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups',
    component: 'pages/authenticated/admin/group',
    navbar: 'dashboard',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups/create',
    component: 'pages/authenticated/admin/group/AddEditGroup',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups/edit/:id',
    component: 'pages/authenticated/admin/group/AddEditGroup',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications/create',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications/edit/:id',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/users/templates',
    component: 'pages/authenticated/user/TemplateList',
    auth: true,
    navbar: 'user_auth',
    sidebar: false,
    footer: '',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/templates/pro',
    component: 'pages/authenticated/user/TemplateList',
    auth: true,
    navbar: 'user_auth',
    sidebar: false,
    footer: '',
    allowedRoles: [roles.proUser],
  },
  {
    path: '/users/templates/create/:id',
    component: 'pages/authenticated/TemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/templates/pro/create/:id',
    component: 'pages/authenticated/ProTemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.proUser],
  },
  {
    path: '/templates/create/:id',
    component: 'pages/authenticated/TemplateDetails',
    auth: false,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/design/details/:id',
    component: 'pages/authenticated/TemplateDetails',
    auth: false,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/users/design/details/:id',
    component: 'pages/authenticated/TemplateDetails',
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/designs/edit/:id',
    component: 'pages/authenticated/user/EditMenuDesign',
    auth: false,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/users/designs/edit/:id',
    component: 'pages/authenticated/user/EditMenuDesign',
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/users/designs/view/:id',
    component: 'pages/authenticated/TemplateDetails',
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/users/designs',
    component: 'pages/authenticated/user/MenuDesignManagement',
    auth: true,
    navbar: 'dashboard',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/my-page',
    component: 'pages/authenticated/MyPage',
    auth: true,
    navbar: 'dashboard',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/menu-orders',
    component: 'pages/authenticated/user/menuOrder/Menu',
    auth: true,
    navbar: 'dashboard',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user, roles.proUser],
  },
  {
    path: '/users/menu-orders/create',
    component: 'pages/authenticated/user/menuOrder/addEditMenuOrder/CreateMenuOrder',
    navbar: 'gm',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/menu-orders/create',
    component: 'pages/authenticated/user/menuOrder/addEditMenuOrder/CreateMenuOrder',
    navbar: 'gm',
    auth: false,
    footer: 'unauth',
  },
  {
    path: '/user/',
    auth: true,
    component: 'pages/authenticated/user/GuestCreate',
    navbar: 'gm',
    footer: 'unauth',
    allowedRoles: [roles.user, roles.admin],
  },
  {
    path: '/users/menu-orders/pro/create',
    component: 'pages/authenticated/user/menuOrder/proUser/CreateMenuOrder',
    navbar: 'gm',
    sidebar: false,
    auth: true,
    allowedRoles: [roles.proUser],
  },
  {
    path: '/users/menu-orders/pro/confirmed',
    component: 'pages/authenticated/user/menuOrder/proUser/OrderConfirmed',
    navbar: 'gm',
    sidebar: false,
    auth: true,
    allowedRoles: [roles.proUser],
  },
  {
    path: '/users/menu-orders/pro/:id',
    component: 'pages/authenticated/user/menuOrder/proUser/ProOrderDesign',
    navbar: 'gm',
    sidebar: false,
    auth: true,
    allowedRoles: [roles.proUser],
  },
];

export default routes;
