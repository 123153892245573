import { roles } from 'config/userRole';
import gmRoutes from './gm.routes';
import webRoutes from './web.routes';

let routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/guest/NotFound',
    auth: false,
    navbar: 'top',
    footer: 'unauth',
  },
  {
    path: '/',
    component: 'pages/guest/Landing',
    auth: false,
    navbar: '',
    footer: '',
  },
  {
    path: '/dashboard',
    component: 'pages/authenticated/Dashboard',
    navbar: 'dashboard',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin, roles.user],
  },
  {
    path: '/about',
    component: 'pages/guest/About',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/signup',
    component: 'pages/guest/Signup',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/login',
    component: 'pages/guest/Login',
    auth: false,
    navbar: 'unauth',
  },
  {
    path: '/forgot-password',
    component: 'pages/guest/ForgotPassword',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/password/reset',
    component: 'pages/guest/ResetPassword',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/activate',
    component: 'pages/guest/Activate',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/terms',
    component: 'pages/guest/Terms',
    auth: false,
    navbar: 'top',
    footer: 'unauth',
  },
  {
    path: '/guide',
    component: 'pages/guest/UserGuide',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/counter',
    component: 'pages/guest/Counter',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/store',
    component: 'pages/guest/Store',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/contact',
    component: 'pages/guest/ContactUs',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/contact/confirmation/',
    component: 'pages/guest/contactpage/Confirmation',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/contact/complete',
    component: 'pages/guest/contactpage/Complete',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/privacy-policy',
    component: 'pages/guest/PrivacyPolicy',
    auth: false,
    navbar: 'top',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/templates',
    component: 'pages/guest/TemplateList',
    auth: false,
    navbar: 'unauth_templates',
    footer: '',
  },
];

// Don't include styleguide in production routes
if (process.env.ENVIRONMENT !== 'production') {
  routes.push({
    path: '/styleguide',
    component: 'pages/guest/Styleguide',
    auth: false,
  });
}

routes = [...routes, ...webRoutes, ...gmRoutes];

export default routes;
