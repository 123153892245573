import { createSlice } from '@reduxjs/toolkit';

const initState = {
  activeStep: 1,
  categories: [],
  atmospheres: [],
  no_of_items: null,
  font: null,
  size: [],
  type: [],
  custom_type: null,
  manuscript: {},
  template: {},
  service: 1,
};

const menuSlice = createSlice({
  name: 'menuOrders',
  initialState: initState,
  reducers: {
    setOrders: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    reset: (state) => {
      for (const key in initState) {
        state[key] = initState[key];
      }
    },
    restore: (state) => {
      const item = JSON.parse(localStorage.getItem('templates'));

      for (const key in initState) {
        state[key] = (item && item[key]) || initState[key];
      }
    },
  },
});

export const { setOrders, reset, restore } = menuSlice.actions;

export default menuSlice.reducer;
