import { createSlice } from '@reduxjs/toolkit';

const initState = {
  data: [],
  meta: {},
};

const menuSlice = createSlice({
  name: 'searchMenuOrder',
  initialState: initState,
  reducers: {
    setSearchFilter: (state, { payload }) => {
      for (const key in payload) {
        state[key] = payload[key];
      }
    },
    reset: (state) => {
      for (const key in initState) {
        state[key] = initState[key];
      }
    },
  },
});

export const { setSearchFilter, reset } = menuSlice.actions;

export default menuSlice.reducer;
