import { createSlice } from '@reduxjs/toolkit';

const initState = {
  value: 'ja',
};

const langSlice = createSlice({
  name: 'lang',
  initialState: initState,
  reducers: {
    setLang: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
