export const roles = {
  proUser: 3,
  admin: 2,
  user: 1,
};

export const userType = {
  basic: -1,
};

export const service = {
  gm: 1,
  web: 2,
};

export const pro2AccountType = {
  sales: 'サントリーセールス',
  liquor: '酒販店',
  business_store: '業務店',
};
